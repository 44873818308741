$.extend($.validator.messages, {
	remote: "Controleer de waarde van dit veld.",
	email: "Geef een geldig e-mailadres in.",
	url: "Geef een geldig webadres in.",
	date: "Geef een geldige datum in.",
	dateISO: "Geef een geldige datum in.",
	number: "Geef een geldig getal in.",
	digits: "Geef enkel getallen in.",
	creditcard: "Geef een geldig creditcardnummer in.",
	equalTo: "Geef dezelfde waarde in.",
	extension: "Geef een geldide extensiecode in.",
	maxlength: $.validator.format("Geef maximaal {0} tekens in."),
	minlength: $.validator.format("Geef minimaal {0} tekens in."),
	rangelength: $.validator.format("Geef een waarde van minstens {0} en maximaal {1} tekens in."),
	range: $.validator.format("Geef een waarde tussen {0} en maximaal {1} in."),
	max: $.validator.format("Geef een waarde kleiner dan of gelijk aan {0} in."),
	min: $.validator.format("Geef een waarde groter dan of gelijk aan {0} in."),
	step: $.validator.format("Geef een veelvoud van {0} in."),
	iban: "Geef een geldig IBAN in.",
	dateNL: "Geef een geldige datum in.",
	phoneNL: "Geef een geldig Nederlands telefoonnummer in.",
	mobileNL: "Geef een geldig Nederlands mobiel telefoonnummer in.",
	postalcodeNL: "Geef een geldige postcode in.",
	bankaccountNL: "Geef een geldig bankrekeningnummer in.",
	giroaccountNL: "Geef een geldig gironummer in.",
	bankorgiroaccountNL: "Geef een geldig bank- of gironummer in."
});

jQuery.validator.setDefaults({
	validClass: 'is-valid',
	errorClass: 'is-invalid',
	errorElement: 'span',
	errorPlacement: function(error, element){
		error.addClass('invalid-feedback');
		element.closest('.form-group').append(error);
	},
	highlight: function(element, errorClass, validClass){
		$(element).addClass(errorClass).removeClass(validClass);
	},
	unhighlight: function(element, errorClass, validClass){
		$(element).removeClass(errorClass).addClass(validClass);
	}
});