toastr.options = {
	"closeButton": true,
	"debug": false,
	"newestOnTop": false,
	"progressBar": true,
	"positionClass": "toast-bottom-left",
	"preventDuplicates": true,
	"onclick": null,
	"showDuration": "500",
	"hideDuration": "1000",
	//"timeOut": "0",
	"extendedTimeOut": "0",
	"showEasing": "easeInExpo",
	"hideEasing": "easeOutExpo",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut",
	"tapToDismiss": false
}