var daterangepicker = {};

daterangepicker.locale = {
	"format": "DD/MM/YYYY",
	"separator": " — ",
	"applyLabel": "Pas toe",
	"cancelLabel": "Annuleer",
	"fromLabel": "Van",
	"toLabel": "Tot",
	"customRangeLabel": "Aangepast",
	"weekLabel": "W",
	"daysOfWeek": [
		"zo",
		"ma",
		"di",
		"wo",
		"do",
		"vr",
		"za"
	],
	"monthNames": [
		"januari",
		"februari",
		"maart",
		"april",
		"mei",
		"juni",
		"juli",
		"augustus",
		"september",
		"oktober",
		"november",
		"december"
	],
	"firstDay": 1
};