numeral.register('locale', 'nl-be', {
	delimiters: {
		thousands: '.',
		decimal: ','
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't'
	},
	ordinal: function(number){
		return number === 1 ? 'ste' : 'de';
	},
	currency: {
		symbol: '€'
	}
});

numeral.locale('nl-be');